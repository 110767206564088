<template>
  <div>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items-per-page="500"
      :items="list.active_trip_details"
      class="elevation-1"
      hide-default-footer
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chassis_no",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
        },
        {
          text: this.checkName(),
          align: "left",
          sortable: false,
          value: "customer_name",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "dashboard/getIsLoading",
      list: "dashboard/getVechileTrips",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("dashboard/vechicleTrips", this.options);
    },
    checkName() {
      return this.options.product == "fleet"
        ? this.$t("driver_name")
        : this.$t("customer_name");
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-data-table__wrapper
  .v-data-table-header
    background: #EAECF0 !important
</style>